.job-container {
    width: 600px;
    padding: 20px;
    background-color: #1e1e1e;
    border-radius: 10px;
    box-shadow: 0px 5px 10px rgb(126, 219, 219);  
  
    h1 {
        margin: 0;
    }
}

@media screen and (max-width: 768px) {
    .job-container {
        width: 100%;
    }
}

.company {
    font-style: italic;
    color: #ccc;
}

.content {
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 768px) {
    .content {
        flex-direction: column;
    }

    .details {
        width: 100%;
    }
}

.details {
    width: 45%;
    h3 {
        font-size: 18px;
        margin-bottom: 10px;
    }
    
    ul {
        list-style-type: none;
        padding: 0;
        
        li {
            margin-left: 8px;
            margin-bottom: 8px;
        }
        
        li:before {
            content: "•";
            color: rgb(126, 219, 219);
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
        }
    }
}
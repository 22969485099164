.gallery-item {
    position: relative;
    margin: 15px;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 5px 10px rgb(126, 219, 219);
    transition: transform 0.3s, box-shadow 0.3s;
}

.gallery-item:hover {
    transform: scale(1.05);
    box-shadow: 0px 10px 20px rgb(126, 219, 219);
}

.gallery-img {
    width: 300px;
    height: 200px;
    object-fit: cover;
    border-radius: 15px;
}

.gallery-info {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    text-align: center;
    opacity: 0;
    transition: opacity 0.3s;
    border-radius: 0 0 15px 15px;
}

.gallery-item:hover .gallery-info {
    opacity: 1;
}

.gallery-info h3 {
    margin: 0;
    color: rgb(126, 219, 219);
}

.gallery-info p {
    margin: 5px 0 0;
}
.highlighted-project {
    z-index: 1;
    margin: 0 10%;
    width: 40%;

    h1 {
        margin: 0;
    }

    p {
        margin: 0;
        padding-top: 10px;
        width: 100%;
    }

    padding: 10px;
    background-color: rgba(0, 0, 0, 75%);
    box-shadow: 0px 5px 10px rgb(126, 219, 219);
    border-radius: 15px;
}

@media screen and (max-width: 768px) {
    .highlighted-project {
        width: 85%;
        margin-bottom: 10px;
    }
}

.highlighted-project-info {
    a {
        color: rgb(126, 219, 219);
    }
}
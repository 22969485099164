.main-content {
    flex: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: stretch;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
    overflow: hidden;
}

@media screen and (max-width: 768px) {
    .main-content {
        flex-direction: column-reverse;
    }
}

.additional-content {
    margin: 50px 10%; 
}
.project-scroll-list {
    position: relative;
    top: 10%;
    right: -5%;
    width: 330px;
    height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 15px;
    -ms-overflow-style: none;  /* IE and Edge */
}

@media screen and (max-width: 768px) {
    .project-scroll-list {
        position: static;
        width: 100%;
        height: auto;
        top: 0;
        right: 0;
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        margin: 30px 0;
    }
}

.project-scroll-list::-webkit-scrollbar {
    width: 8px;
}

.project-scroll-list::-webkit-scrollbar-thumb {
    border-radius: 15px;
    border: 2px solid rgb(126, 219, 219);
}

.project-scroll-list::-webkit-scrollbar-thumb:hover {
    background: rgba(126, 219, 219, 0.093);
}

@media screen and (max-width: 768px) {
    /* Hide vertical scrollbar */
    .project-scroll-list::-webkit-scrollbar {
        height: 8px;
    }

    .project-scroll-list::-webkit-scrollbar-thumb {
        background: rgba(126, 219, 219, 0.5);
    }
}
html {
  scroll-behavior: smooth;
}

body {
  font-family: Poly Sans, sans-serif;
  font-size: 1em;
  color: white;
  background-color: rgb(0, 0, 0);
  overflow-x: hidden;
  margin: 0;
}

h1 {
  font-size: 3em;
  color: rgb(126, 219, 219);
}

h2 {
  font-size: 1.5em;
}

p {
  font-size: 1.1em;
}

a {
  color: white;
  text-decoration: none;
}

a:link {
  color: white;
  text-decoration: none;
}

a:visited {
  color: white;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

a:visited {
  color: white;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 15px;
  border: 2px solid rgb(126, 219, 219);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(126, 219, 219, 0.093);
}
.vertical-list-element {
    display: inline-block;
    padding: 8px;  
    border-radius: 15px;
    box-shadow: 0px 5px 10px rgb(126, 219, 219);  
    margin-bottom: 10px;
}

table {
    width: 100%;
    border-collapse: collapse;
}

thead th {
    border-bottom: solid 1px rgb(255, 255, 255);
    padding: 10px;
}

tbody td {
    padding: 10px;
}


.highlight {
    color: rgb(126, 219, 219);
    font-weight: bold;
}

th, td {
    text-align: left;
}
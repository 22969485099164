.content-block {
    
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.content-block.reverse {
    flex-direction: row-reverse;
}

@media screen and (max-width: 768px) {
    .content-block {
        flex-direction: column;
    }
    .content-block.reverse {
        flex-direction: column;
    }
}

.content-img {
    width: 300px;
    height: 200px;
    object-fit: cover;
    border-radius: 15px;
    box-shadow: 0px 5px 10px rgb(126, 219, 219);
}

.content-text {
    padding: 20px;
}

@media screen and (min-width: 768px) {
    .content-text {
        width: 60%;
    }
}

.content-text h2 {
    color: rgb(126, 219, 219);
    margin-bottom: 10px;
    
    a {
        color: rgb(126, 219, 219)
    }
}

.content-text p, .content-text ul {
    color: white;
}

.content-text ul {
    list-style-type: none;
    padding: 0;
}

.content-text li {
    margin-bottom: 5px;
}
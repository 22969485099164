.popular-projects {
    margin: 10% 10%;
    width: 80%;
}

.popular-projects-titel {
    text-align: center;
    margin-bottom: 50px;
}

.popular-projects-list {
    justify-content: center;

    ul {
        display: flex;
        gap: 20px;
        margin-bottom: 0;
        padding-left: 0;
        list-style: none;
    }
}

@media screen and (max-width: 768px) {
    .popular-projects-list ul {
        flex-direction: column;
    }
}
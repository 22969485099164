.container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 30px;
}

@media screen and (max-width: 768px) {
    .container {
        flex-direction: column;
    }
}

.left-column, .right-column {
    width: 48%;
}

@media screen and (min-width: 768px) {
    .left-column, .right-column {
        display: grid;
    }
}

@media screen and (max-width: 768px) {
    
    .left-column, .right-column {
        width: 100%;
        margin-bottom: 20px;
    }
}

.vertical-list {
    display: grid;
    gap: 20px;
    justify-content: center;
    
    h1 {    
        margin-bottom: 0px;
    }
}

.download-resume {
    margin-top: 130px;
}

.download-button {
    display: inline-block;
    background-color: #414a41;
    color: rgb(126, 219, 219);
    padding: 20px 40px;
    font-size: 18px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    box-shadow: 0px 4px 8px rgb(126, 219, 219);
}

.download-button:hover {
    background-color: rgb(126, 219, 219);
    color: #414a41;
}

.download-button:active {
    transform: scale(0.98);
}
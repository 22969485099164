.canvas {
    overflow: hidden;
}

.menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 10;

    background-color: rgba(0, 0, 0, 75%);
    box-shadow: 0px 5px 10px rgb(126, 219, 219);
    border-radius: 15px;
  }
  
.overlay-menu {
  position: absolute;
  top: 10vw;
  left: 1vw;
  width: 20vw;
  height: 40vh;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;

  background-color: rgba(0, 0, 0, 75%);
  box-shadow: 0px 5px 10px rgb(126, 219, 219);
  border-radius: 15px;
}

.menu button, .overlay-menu button {
  display: inline-block;
  background-color: #414a41;
  color: rgb(126, 219, 219);
  padding: 20px 40px;
  font-size: 18px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.menu button:hover, .overlay-menu button:hover {
  background-color: rgb(126, 219, 219);
  color: #414a41;
}

.menu button:active, .overlay-menu button:active {
  transform: scale(0.98);
}

.difficulty {
  display: flex;
}
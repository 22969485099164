.popular-project-element {
    height: fit-content;
    position: relative;
    
    img {
        width: 100%;
        border-radius: 25px;
        overflow: hidden;
        box-shadow: 0px 5px 10px rgb(126, 219, 219);
    }            

    

    .element-text {
        width: 100%;
        z-index: 10;
        text-transform: inherit;
        position: absolute;
        text-align: center;
        top: 5%;                
    }

    
}

.popular-project-element:hover{
    transform: scale(1.05);
}
header div {
    color: white;
    display: flex;
    padding-bottom: 10px;
    border-bottom: solid 1px rgb(255, 255, 255);
    justify-content: space-between;
    padding-top: 15px;
    margin: 0 10%;
    list-style: none;

    nav {
        display: flex;
        gap: 20px;

        font-size: 1.3em;
    }


}

@media screen and (max-width: 768px) {
    nav {
        flex-direction: column;
        align-items: center;
    }

    nav li {
        margin-bottom: 10px;
    }
}

.backgroundimage {
    position: absolute;
    top: -150px;
    z-index: -1;
    width: 100%;

    img {
        width: 100vw;        
    }
}

.backgroundimage::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, transparent 50%, rgb(0, 0, 0) 100%);
    pointer-events: none;
    mask-image: linear-gradient(linear, transparent 50%, rgb(0, 0, 0) 100%);
    mask-size: cover;
}
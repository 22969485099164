.project-scroll-element {
    height: 75px;
    width: 250px;
    padding: 20px;
    margin: 10px;
    border-radius: 15px;
    border: 1px solid rgb(126, 219, 219);
    background-color: rgba(0, 0, 0, 75%);
    box-shadow: 0px 5px 10px rgb(126, 219, 219);
    cursor: pointer;
    transition: all 0.3s;
}

@media screen and (max-width: 768px) {
    .project-scroll-element {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 150px;
        flex-shrink: 0;
        margin: 10px;
        white-space: normal;

        h2 {
            margin: 0;
            text-align: center;
        }
    }
}

.project-scroll-element:hover {
    background: rgba(126, 219, 219, 0.137);
}
footer {
    display: flex;
    justify-content: center;
    margin: 30px 0;

    font-size: 1.3em;

    ul {
        margin-bottom: 0;
        padding-left: 0;
        border-bottom: 1px solid black;
        width: fit-content;

        li {
            list-style: none;
            display: inline-block;
            padding: 8px;
        }
    }
}
.aboutme-list {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin: 0 10%;
    width: 80%;
    border-bottom: 1px solid rgb(255, 255, 255);    
}

@media screen and (max-width: 768px) {
    .aboutme-list {
        flex-direction: column;
    }
}